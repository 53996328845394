import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

import { TextInput } from '../commons';
import { SnackBar, SpinnerV } from '../widgets';
import { PrivacyPolicyDialog } from '../terms';
import { FormControlLabel, Checkbox, IconButton,Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactTelInput from 'react-telephone-input';
import Flags from '../../assets/images/flags.png';
import TagManager from 'react-gtm-module';


function SharedForm(props) {

  const router = useRouter();
  const [ first_name, setFirstName ] = useState('');
  const [ last_name, setLasttName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ phone_one, setPhoneOne ] = useState('');
  const [ formErrors, setFormErrors ] = useState([]);
  const [ dialog, setDialog ] = useState(false);
  const [ shared, setShared ] = useState(false);
  const [ sharedEmail, setSharedEmail ] = useState(['']);
  const [ sharedErrors, setSharedErrors ] = useState([]);
  const [ assisted_selling, setAssistedSelling ] = useState(false);

  const [ openAlert, setOpenAlert ] = useState(false);
  const [ messageAlert, setMessageAlert ] = useState('');
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    const {titular, actions, isUser} = props;
    if(!isUser) {
      setFirstName(titular.first_name || '');
      setLasttName(titular.last_name || '');
      setEmail(titular.email || '');
      setPhoneOne(titular.phone_one || '');
      actions.handleStateAffiliation('titular', titular);
    }
  }, [])

  const handleInput = (event, telNumber) => {
    if(event === 'phone_one') {
      setPhoneOne(telNumber);
      validateField('phone_one', telNumber);
    } else {
      const { name, value } = event.target;
      switch (name) {
        case 'first_name':
          setFirstName(value);
          break;
        case 'last_name':
          setLasttName(value);
          break;
        case 'email':
          setEmail(value);
          break;
          default:
          break;
      }
      validateField(name, value);
    }
  }

  const validateField = (field, value) => {
    let validate = false;
    switch (field) {
      case 'first_name':
        validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{3,})$/.test(value);
        (!validate) ? formErrors.first_name = "Nombre inválido." : delete formErrors.first_name;
        break;
      case 'last_name':
        validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{3,})$/.test(value);
        (!validate) ? formErrors.last_name = "Apellido inválido." : delete formErrors.last_name;
        break;
      case 'email':
        validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
        (!validate) ? formErrors.email = "Correo electrónico inválido." : delete formErrors.email;
        break;
      case 'phone_one':
        validate = /^([0-9()+ -]{10,})$/.test(value);
        (!validate && value) ? formErrors.phone_one = "Teléfono inválido. Ejemplo: (+58) 416 765-4321" : delete formErrors.phone_one;
        break;
      default:
        break;
    }
    setFormErrors({ ...formErrors });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    let quotationForSend = props.quotationForSend;
    const percent_off = quotationForSend?.percent_off !== 'default' ? quotationForSend.percent_off : 0;
    let quotation = {};
    quotation = {
      first_name,
      last_name,
      email,
      phone_one,
      percent_off,
      assisted_selling,
      beneficiaries: [...props.quotation],
      country: props.quotation[0].country,
      link_rrss_discount: props.share_link_discount || '',
      ...shared && {
        share_with: sharedEmail,
      }
    };
    if(props.isLanding) quotation.is_ms = true;
    quotationForSend.parients = [ ...props.quotation ];
    props.actions.saveQuotationClient({ first_name, last_name, email, phone_one });
    props.actions.saveQuotation(quotationForSend);
    const response = await props.actions.sharedQuotation({ ... quotation, isoCode: props.isoCode });
    if(response) {
      setOpenAlert(response.openAlert);
      setMessageAlert(response.messageAlert);
      setLoading(false);
      const pathname = '/quotation/sendQuotation';
      const asPath = '/cotizacion-enviada';
      if(response.success === true) {
        const tagManagerArgs = { dataLayer: { event: 'evento-cotizacion-enviada-paso-seis' } };
        TagManager.dataLayer(tagManagerArgs);
        if (props.handleClose) props.handleClose();
        setTimeout(() => {
          if(props.isUser) {
            if(props.isAdmin) {
              router.push({
                pathname,
                query: { isUser: props.isUser, isAdmin: true }
              }, asPath).then(() => window.scrollTo(0, 0));
            } else {
              router.push({
                pathname,
                query: { isUser: props.isUser }
              }, asPath).then(() => window.scrollTo(0, 0));
            }
          } else if (props.isLanding) {
            router.push({
              pathname,
              query: { isLanding: true }
            }, asPath).then(() => window.scrollTo(0, 0));
          } else {
            router.push(pathname, asPath).then(() => window.scrollTo(0, 0));
          }
        }, 3000);
      }
    }
  }

  const handleClose = () => setOpenAlert(false);

  const closeDialog = (e, reason) => {
    if (reason === "backdropClick") {
      return false;
    }

    if (reason === "escapeKeyDown") {
      return false;
    }

    if (!reason) {
      setDialog(false);
    }
  };

  const toggleShared = (value) => {
    if (!value) {
      setSharedEmail(['']);
      setSharedErrors([]);
    }
    setShared(value);
  };

  const toggleAsissted = (value) => {
    setAssistedSelling(value);
  };

  const handleInputShared = (event, index) => {
    const { value } = event.target;
    let validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
    const tmp = sharedEmail;
    tmp[index] = value;
    setSharedEmail([...tmp]);

    const errors = sharedErrors;
    if (!validate) errors[index] = 'Correo electrónico inválido.';
    else errors.splice(index, 1);
    setSharedErrors([...errors]);
  };

  const removeShared = (idx) => {
    const tmp = sharedEmail;
    const errors = sharedErrors;
    tmp.splice(idx, 1);
    setSharedEmail([...tmp]);
    errors.splice(idx, 1);
    setSharedErrors([...errors]);
  };

  return(
    <form autoComplete="false" className="flex justify-center items-center flex-wrap w-full" onSubmit={(e) => handleSubmit(e)}>
      { loading && <SpinnerV/> }
      <TextInput
        name="first_name"
        label="Nombre"
        placeholder="Nombre"
        value={first_name}
        helperText={formErrors.first_name}
        errors={formErrors.first_name}
        addClass="wInputTwo"
        onChange={e => handleInput(e)}
        required={true}
      />
      <TextInput
        name="last_name"
        label="Apellido"
        placeholder="Apellido"
        value={last_name}
        helperText={formErrors.last_name}
        errors={formErrors.last_name}
        addClass="wInputTwo"
        onChange={e => handleInput(e)}
        required={true}
      />
      <TextInput
        name="email"
        type="email"
        label="Correo electrónico"
        placeholder="Correo electrónico"
        value={email}
        helperText={formErrors.email}
        errors={formErrors.email}
        addClass="wInputTwo"
        onChange={e => handleInput(e)}
        required={true}
      />
      <ReactTelInput
        required={true}
        onChange={(telNumber) => handleInput('phone_one', telNumber)}
        value={phone_one || '+58'}
        defaultCountry="ve"
        flagsImagePath={Flags}
        className={(!phone_one) ? 'telInputTwo' : (formErrors.phone_one) ? 'telInputTwoError' : "telInputTwo"}
      />
      <div className="w-full px-2 py-3">

        { (props.isAlly !== null ||  props.isAllyMaster !== null) && (!props.isAlly && props.isAllyMaster) &&
        <div>
        <FormControlLabel
          className="w-auto"
          label={<span
          className="text-sm w-full text-gray-800"
          >
          Quiero que un Big Broder impulse la afiliación
          <Tooltip title="Quiero que un Big Broder impulse la afiliación"><span className="font-bold text-purple-500"> (?)</span></Tooltip></span>}
          control={
            <Checkbox
              name="assisted_selling"
              // className={ assisted_selling === true ? 'checkBoxActive' : 'checkBox'}
              onClick={(e) => toggleAsissted(!assisted_selling)}
              checked={assisted_selling || false}
            />
          }
        />
         {assisted_selling && (
                <div className="bg-purple-200 p-3 rounded mb-5">
                  <p>Con esta opción uno de nuestros asesores comerciales contactará al titular para explicar en detalle el producto, responder sus dudas e impulsar la afiliación. Nosotros nos encargamos de concretar la afiliación y ¡tú recibes el pago por referido!</p>
                </div>
              )}
        </div>
        }





        <div className="w-full flex flex-wrap justify-between items-center">

          <div>
            <FormControlLabel
              label={
                <span className="text-sm w-full text-gray-800">
                  ¿Quieres compartir esta cotización con alguien más?
                </span>
              }
              control={
                <Checkbox
                  checked={shared || false}
                  onClick={() => toggleShared(!shared)}
                  name="shared"
                  value={shared}
                />
              }
            />
          </div>


          <div className="w-full md:w-auto text-center md:text-right">
            {shared && (
              <div
                className="font-bold text-pink-500 text-sm my-3 md:my-0 cursor-pointer"
                onClick={() => setSharedEmail(sharedEmail.concat(['']))}
              >
                + Agregar correo
              </div>
            )}
          </div>
        </div>


        {shared && (
          <div className="w-full flex flex-wrap">
            {sharedEmail.map((se, idx) => (
              <div className="flex items-center wInputTwo">
                <TextInput
                  name="shared_email"
                  type="email"
                  label="Correo electrónico"
                  placeholder="Correo electrónico"
                  value={se}
                  helperText={sharedErrors[idx]}
                  errors={sharedErrors[idx]}
                  addClass="wInputFull h-20"
                  onChange={e => handleInputShared(e, idx)}
                  required={false}
                />
                {sharedEmail.length > 1 && (
                  <IconButton aria-label="delete" style={{marginTop: -10}} onClick={() => removeShared(idx)}>
                    <DeleteIcon fontSize="small"/>
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <button
        id="enviar-cotización-paso5"
        type="submit"
        disabled={(
          Object.keys(formErrors).length > 0 ||
          !first_name ||
          !last_name ||
          !email ||
          !phone_one ||
          (shared && (sharedErrors.filter(e => e).length > 0 || sharedEmail.some(s => !s)))
        )}
        className="primary-button my-8"
      >
        Enviar cotización
      </button>
      <PrivacyPolicyDialog
        open={dialog}
        handleClose={closeDialog}
      />
      <SnackBar openAlert={openAlert} messageAlert={messageAlert} handleClose={handleClose}/>
    </form>
  )
}


const mapStateToProps = (state, props) => {
	const quotation = state.affiliation.get('quotation');
	const quotationForSend = state.customers.get('quotation');
  const share_link_discount = state.ally.get('share_link_discount');
  const titular = state.affiliation.get('titular');
  const isoCode = state.currencies.get('isoCode');
  const isAllyMaster = state.login.get('id_user') && (parseInt(state.login.get('type')) === 110);
  const isAlly = state.login.get('id_user') && (parseInt(state.login.get('type')) === 100);

  return {
    quotation,
    quotationForSend,
    share_link_discount,
    titular,
    isoCode,
    isAlly,
    isAllyMaster
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(SharedForm));
